import React from 'react'

const PlayArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M13.75 10.71a.568.568 0 0 1-.198.199l-4.76 2.907a.23.23 0 0 1-.124.066.588.588 0 0 1-.272 0 .59.59 0 0 1-.132-.049.517.517 0 0 1-.19-.19A.518.518 0 0 1 8 13.37V7.538c0-.1.024-.19.074-.273a.524.524 0 0 1 .19-.19A.46.46 0 0 1 8.537 7c.093.005.178.03.255.074L13.552 10a.44.44 0 0 1 .198.19.54.54 0 0 1 0 .52m3.069-8.022A9.917 9.917 0 0 0 9.65.006C4.141.198-.186 4.838.006 10.348a9.932 9.932 0 0 0 3.176 6.96c1.947 1.817 4.494 2.788 7.167 2.685 5.51-.192 9.837-4.831 9.645-10.342a9.942 9.942 0 0 0-3.175-6.963"
    />
  </svg>
)

export { PlayArrow }
