import React, { Component } from 'react'
import style from './style.module.scss'
import multiClass from '../../helpers/multiClass'

import apto103nw from '../../images/grid-home/apto-103-nw.jpg'
import apto104nw from '../../images/grid-home/apto-104-nw.jpg'
import apto213n from '../../images/grid-home/apto-213-n.jpg'
import apto214n from '../../images/grid-home/apto-214-n.jpg'
import apto306sw from '../../images/grid-home/apto-306-sw.jpg'
import casacondverde from '../../images/grid-home/casa-cond-verde.jpg'
import cozinha28n from '../../images/grid-home/cozinha-28-n.jpg'
import cozinharj from '../../images/grid-home/cozinha-rj.jpg'
import espgourmet from '../../images/grid-home/esp-gourmet.jpg'

class PortfolioTablet extends Component {
  state = {}

  render() {
    const projectsList = [
      {
        gap: false,
        number: '01',
        serviceType: 'PROJETO + OBRA',
        title: 'Apartamento 214n',
        className: 'itemRectangle',
        image: apto214n,
        color: 'rgba(255, 191, 104, 0.8)',
        triangleBackground: 'bottomRight',
        projectLink: '/apartamento-214-n'
      },
      {
        gap: false,
        number: '02',
        serviceType: 'PROJETO',
        title: 'Casa Cond. Verde',
        className: 'itemSquare',
        image: casacondverde,
        color: 'rgba(70, 183, 216, 0.8)',
        triangleBackground: 'topRight',
        projectLink: '/casa-cond-verde'
      },
      {
        gap: false,
        number: '04',
        serviceType: 'PROJETO + OBRA',
        title: 'Apartamento 103nw',
        className: 'itemSquare',
        image: apto103nw,
        color: 'rgba(254, 137, 131, 0.8)',
        triangleBackground: 'topLeft',
        projectLink: '/apartamento-103-nw'
      },
      {
        gap: false,
        number: '06',
        serviceType: 'PROJETO',
        title: 'Cozinha 28n',
        className: 'itemRectangle',
        image: cozinha28n,
        color: 'rgba(84, 58, 150, 0.8)',
        triangleBackground: 'bottomRight',
        projectLink: '/cozinha-28-n'
      },
      {
        gap: false,
        number: '05',
        serviceType: 'PROJETO + OBRA',
        title: 'Apartamento 213n',
        className: 'itemSquare',
        image: apto213n,
        color: 'rgba(18, 178, 178, 0.8)',
        triangleBackground: 'bottomLeft',
        projectLink: '/apartamento-213-n'
      },
      {
        gap: false,
        number: '09',
        serviceType: 'PROJETO + OBRA',
        title: 'Apartamento 306sw',
        className: 'itemSquare',
        image: apto306sw,
        color: 'rgba(255, 191, 104, 0.8)',
        triangleBackground: 'topLeft',
        projectLink: '/apartamento-306-sw'
      },
      {
        gap: false,
        number: '08',
        serviceType: 'PROJETO',
        title: 'Apartamento 104nw',
        className: 'itemRectangle',
        image: apto104nw,
        color: 'rgba(70, 183, 216, 0.8)',
        triangleBackground: 'bottomRight',
        projectLink: '/apartamento-104-nw'
      },
      {
        gap: false,
        number: '03',
        serviceType: 'PROJETO',
        title: 'Cozinha RJ',
        className: 'itemSquare',
        image: cozinharj,
        color: 'rgba(254, 137, 131, 0.8)',
        triangleBackground: 'topRight',
        projectLink: '/cozinha-rj'
      },
      {
        gap: false,
        number: '07',
        serviceType: 'PROJETO',
        title: 'Espaço Gourmet',
        className: 'itemSquare',
        image: espgourmet,
        color: 'rgba(84, 58, 150, 0.8)',
        triangleBackground: 'bottomLeft',
        projectLink: '/espaco-gourmet'
      }
    ]
    return (
      <section className={multiClass(style.gridMobile)}>
        {projectsList.map(item => (
          <div
            key={item.number}
            style={{
              backgroundImage: `url(${item.image})`
            }}
            className={multiClass(style[item.className], style.gridItem)}
          >
            <div
              style={{ '--item-color': item.color }}
              className={multiClass(
                style.projectTextContainer,
                style[item.triangleBackground]
              )}
              onClick={() => {
                window.location = item.projectLink
              }}
              role="button"
              tabIndex="-1"
              onKeyDown={() => {}}
            >
              <div className={style.borderBackground} />
              <p>{item.serviceType}</p>
              <h4>{item.title}</h4>
            </div>
          </div>
        ))}
      </section>
    )
  }
}

export default PortfolioTablet
