import React from 'react'
import PropTypes from 'prop-types'

const LeftArrow = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    style={style}
  >
    <path
      fillRule="evenodd"
      d="M7.5 15L0 7.5 7.5 0l1.325 1.325-5.232 5.254H15v1.842H3.593l5.232 5.254z"
    />
  </svg>
)

const RightArrow = () => (
  <LeftArrow
    style={{
      transform: 'rotate(180deg)'
    }}
  />
)

export { LeftArrow, RightArrow }

LeftArrow.propTypes = {
  style: PropTypes.objectOf(PropTypes.string)
}

LeftArrow.defaultProps = {
  style: {}
}
