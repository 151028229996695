import React from 'react'
import PropTypes from 'prop-types'

const ImiraIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="24"
    viewBox="0 0 19 24"
  >
    <path
      fill="#B9BFC8"
      fillRule="evenodd"
      d="M9.133 12.405c-3.086 0-5.588-.758-5.588-1.692 0-.934 2.502-1.691 5.588-1.691 3.087 0 5.589.757 5.589 1.691s-2.502 1.692-5.589 1.692zm.994 3.107l1.087-.69.65 1.794-1.737-1.104zM7.46 13.818l.005-.016H10.845l.005.015-1.695 1.078-1.696-1.077zm-1.014 2.798l.65-1.794 1.087.69-1.737 1.104zM17.547 4.184c-.526-.042-2.086-.277-2.963-.846-.51-.33-.868-.8-1.246-1.298C12.634 1.115 11.794 0 9.79 0H8.492C6.399 0 5.286 1.57 4.929 2.04l-.004.006.003-.025c-.378.498-.735.968-1.245 1.297-.878.57-2.437.805-2.964.847A.782.782 0 0 0 0 4.911v.578c.383.047 1.463.084 1.463 3.536v.02c0 3.353 2.636 4.518 4.35 4.723L2.487 22.95a.781.781 0 1 0 1.47.532l1.907-5.263 3.292-2.09 3.29 2.09 1.908 5.263a.782.782 0 1 0 1.47-.532l-3.329-9.186c1.694-.211 4.31-1.38 4.31-4.72 0-3.452 1.08-3.489 1.463-3.537v-.577a.783.783 0 0 0-.72-.747z"
    />
  </svg>
)

const AnnaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="28"
    viewBox="0 0 20 28"
  >
    <path
      fill="#B9BFC8"
      fillRule="evenodd"
      stroke="#B9BFC8"
      d="M7.772 17.287l3.37 1.828 3.308-1.795-.79-2.157-.202.032a.298.298 0 0 1-.241-.07l-.53-.478c-1.283.208-2.373.311-3.217.36l-.576.685a.29.29 0 0 1-.222.102h-.007l-.345-.008-.548 1.5zm2.752 2.117l-2.942-1.596-1.468 4.023 4.41-2.427zm5.56 2.38l-1.443-3.943-2.881 1.564 4.324 2.38zm-15.08-20.5c.012-.062.074-.267.4-.282C2.88.932 3.51 2.634 3.757 3.3c.226.612.448 1.39.684 2.215.583 2.046 1.31 4.593 2.508 5.926.762.848 3.781 1.208 5.403 1.402 1.921.23 4.18.487 4.285.499 1.495-.074 2.217.44 2.255.467.123.092.145.26.05.378a.294.294 0 0 1-.398.052c-.006-.005-.62-.424-1.915-.353-.253.05-.488.1-.719.147-.349.072-.69.142-1.077.216l-.357.675a.282.282 0 0 1-.208.145l-.037.006 4.233 11.565a.268.268 0 0 1-.174.346.298.298 0 0 1-.094.015.284.284 0 0 1-.268-.182l-1.56-4.26a.318.318 0 0 1-.063-.025l-5.162-2.842-5.274 2.901c-.012.007-.025.01-.038.015l-1.537 4.21a.283.283 0 0 1-.268.183.29.29 0 0 1-.094-.016.268.268 0 0 1-.174-.345l3.967-10.867-.052-.001a.289.289 0 0 1-.218-.106l-.514-.636c-.014-.017-.02-.038-.03-.057-1.394-.199-3.412-1.004-3.649-3.973-.188-2.366-1.037-7.859-1.337-8.643-.152-.4-.408-.57-.596-.645-.347-.136-.335-.36-.324-.426z"
    />
  </svg>
)

const NicolasIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="27"
    viewBox="0 0 22 27"
  >
    <g fill="#B9BFC8" fillRule="evenodd">
      <path d="M.798.272s2.551 7.556 3.27 10.304c0 0 .013.048.127.025.113-.025 1.35-.2 1.519-.712 0 0 .77-.133.852-.965.083-.831-.868-3.404-.868-3.404S4.572 2.35 3.487.874c0 0-.25-.416-.553-.594C2.63.103 1.751-.1.894.056c0 0-.152.03-.096.216z" />
      <path
        fillRule="nonzero"
        d="M22 13.205s-.06.183-.094.312c-.032.127-.077.073-.077.073-.922-.827-1.666-.842-1.919-.85-.254-.008-.996.05-.996.05-.04.054-.04.216-.04.216l.261 1.446 1.638 12.35c.025.18-.088.198-.088.198l-1.042-.005c-.243 0-.243-.181-.243-.181l-3.293-12.373-3.242.938s-2.86.692-3.32 1.104c-.462.412-.715.637-1.272.904-.556.268-1.216.175-1.216.175-.238 1.016-2.265 7.409-2.265 7.409-.493 1.826-.624 1.968-.624 1.968l-1.137-.003c-.208-.01-.121-.18-.121-.18l.93-8.018s.16-1.195.214-1.312c.052-.117.147-.226.147-.226-.511-.065-.98-.191-.98-.191-1.96-.656-1.18-2.83-1.18-2.83s.88-3.382 1.003-3.84c.123-.46.107-1.116.049-1.288-.062-.17-.32-.927-.32-.927L1.75 5.12s.208-.07.522-.15c.303-.077.263.046.26.056.234-.116.487-.206.487-.206l.552.937-.29.622c-.294.043-.138.223.352 1.585.49 1.36.393 1.828.286 2.502-.105.675-.66 2.492-1.003 3.926-.343 1.435.81 1.745.81 1.745s3.117.443 3.872.464c.756.02 1.393-.653 1.828-.948.433-.295.991-.483.991-.483l-.112-.324c-2.522.693-4.962 1.08-4.962 1.08-.462-1.087.443-2.337 1.81-2.636.905-.235 3.905-.615 3.905-.615 6.623-1.272 8.485-1.173 9.575-.454 1.094.72 1.367.983 1.367.983z"
      />
    </g>
  </svg>
)

const JuliaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="23"
    viewBox="0 0 18 23"
  >
    <path
      fill="#B9BFC8"
      fillRule="nonzero"
      d="M13.843 12.122H4.158V.855A.85.85 0 0 1 5.004 0h7.992a.85.85 0 0 1 .846.855v11.267zm3.311-5.752h-1.386a.85.85 0 0 0-.846.856v5.988H3.078V7.226a.85.85 0 0 0-.846-.856H.846A.85.85 0 0 0 0 7.226a.85.85 0 0 0 .846.855h.54v5.988a.85.85 0 0 0 .846.856h1.926v.383a.85.85 0 0 0 .846.855h2.322v2.393L2 20.09a.87.87 0 0 0-.614.823v1.23a.85.85 0 0 0 .846.856.85.85 0 0 0 .846-.855v-.586l5.076-1.462v2.049A.85.85 0 0 0 9 23a.85.85 0 0 0 .846-.855v-1.228-.846l5.076 1.485v.589a.85.85 0 0 0 .846.855.85.85 0 0 0 .846-.855v-1.232a.871.871 0 0 0-.61-.822l-5.238-1.532v-2.396h2.23a.85.85 0 0 0 .846-.855v-.383h1.926a.85.85 0 0 0 .846-.855V8.08h.54A.85.85 0 0 0 18 7.226a.85.85 0 0 0-.846-.856z"
    />
  </svg>
)

const TrianglesTeam = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="103"
    height="208"
    viewBox="0 0 103 208"
    className={className}
  >
    <g fill="none" fillRule="nonzero" opacity=".5">
      <path fill="#EFEFEF" d="M104 0v104L52 52z" />
      <path fill="#B9BFC8" d="M0 104V0l52 52z" />
      <path fill="#EFEFEF" d="M104 208H0l104-104z" />
    </g>
  </svg>
)

const MylenaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="25"
    viewBox="0 0 19 25"
  >
    <path
      fill="#B9BFC8"
      fillRule="nonzero"
      d="M19 11.194l-.332-.956h-8.003l-.152-1.1 6.685-1.815L15.164 0H3.836L1.802 7.323c2.585.702 4.635 1.258 6.685 1.816l-.153 1.1H.332c-.111.317-.22.636-.332.955l2.915 2.346L.827 24.783l.936.217 2.552-11.135h10.37c.887 3.872 1.72 7.503 2.551 11.135l.936-.217-2.088-11.243L19 11.194z"
    />
  </svg>
)

const TherezaIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="29"
    viewBox="0 0 14 29"
  >
    <path
      fill="#B9BFC8"
      fillRule="nonzero"
      d="M12.598 13.266H3.33V1.446C3.33.649 2.702 0 1.928 0h-.526C.627 0 0 .648 0 1.447v13.808c0 .25.061.485.169.69.086.164.202.309.341.427.242.206.553.33.892.33H3.84v.81h2.585v7.76h-.582v1.593H1.838c-.442 0-.8.37-.8.826v.155h.172a.701.701 0 0 0-.172.46c0 .383.301.694.672.694.372 0 .673-.31.673-.694a.701.701 0 0 0-.173-.46h9.579a.701.701 0 0 0-.172.46c0 .383.3.694.672.694.371 0 .672-.31.672-.694a.701.701 0 0 0-.172-.46h.172v-.155a.813.813 0 0 0-.8-.826H8.156v-1.594h-.582v-7.758h2.585v-.81h2.44c.773 0 1.401-.648 1.401-1.448v-.543c0-.799-.628-1.446-1.402-1.446z"
    />
  </svg>
)

const AliceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="28"
    viewBox="0 0 19 28"
  >
    <path
      fill="#B9BFC8"
      fillRule="evenodd"
      d="M18.089 6.896c-.525-.31-1.16-.31-1.712 0l-1.768 1.046v-.057a2.095 2.095 0 0 0-1.85-2.091c-.138-.037-.434-.068-.84-.094a2.96 2.96 0 0 0 .824-2.056c0-1.623-1.285-2.939-2.87-2.939a2.85 2.85 0 0 0-2.375 1.29C7.006.822 5.869 0 4.543 0 2.768 0 1.328 1.473 1.328 3.291c0 .976.424 1.844 1.083 2.447a8.363 8.363 0 0 0-.56.056C.8 5.907 0 6.784 0 7.885v7.096c0 1.158.911 2.12 2.071 2.12H4.1L.917 26.269a.965.965 0 0 0 .57 1.226.93.93 0 0 0 .313.055.94.94 0 0 0 .883-.638l3.406-9.814 2.693-.026h.053c.002.009 0 .018.004.026l3.405 9.814a.94.94 0 0 0 .883.638.93.93 0 0 0 .314-.055.966.966 0 0 0 .57-1.226l-3.192-9.197h1.774c1.133 0 2.071-.933 2.071-2.12v-.056l1.768 1.045c.276.17.552.226.856.226.304 0 .58-.084.856-.226.525-.31.856-.876.856-1.526V8.422c-.056-.65-.358-1.215-.911-1.526"
    />
  </svg>
)

export {
  ImiraIcon,
  TrianglesTeam,
  AnnaIcon,
  NicolasIcon,
  JuliaIcon,
  MylenaIcon,
  TherezaIcon,
  AliceIcon
}

TrianglesTeam.propTypes = {
  className: PropTypes.string
}

TrianglesTeam.defaultProps = {
  className: ''
}
