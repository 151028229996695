import React from 'react';
import { WhatsappIcon } from '../SocialIcons';

const WhatsAppButton = () => {
  const handleClick = () => {
    // redirect to whatsapp
    window.open('https://wa.me/556195697995', '_blank');
  };

  return (
    <div
      className="whatsapp-button"
      style={{
        backgroundColor: '#25D366',
        borderRadius: '50%',
        width: '64px',
        height: '64px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '112px',
        right: '40px',
        zIndex: '100',
        cursor: 'pointer'
      }}
      onClick={handleClick}
    >
      <WhatsappIcon/>
    </div>
  );
};

export default WhatsAppButton;
