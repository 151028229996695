import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import {
  InstagramIcon,
  LinkedinIcon,
  PortfolioIcon,
  PinterestIcon
} from '../SocialIcons'

class SocialLinksMobile extends Component {
  state = {}

  render() {
    const { member } = this.props
    return (
      <div className={style.socialLinksMobile}>
        {member.instagram && (
          <a href={member.instagram} target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </a>
        )}
        {member.linkedin && (
          <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
            <LinkedinIcon />
          </a>
        )}
        {member.pinterest && (
          <a href={member.pinterest} target="_blank" rel="noopener noreferrer">
            <PinterestIcon />
          </a>
        )}
        {member.portfolio && (
          <a href={member.portfolio} target="_blank" rel="noopener noreferrer">
            <PortfolioIcon />
          </a>
        )}
      </div>
    )
  }
}

SocialLinksMobile.propTypes = {
  member: PropTypes.instanceOf(Object)
}

SocialLinksMobile.defaultProps = {
  member: {}
}

export default SocialLinksMobile
