import React from 'react'

const TriangleLife = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="73"
    viewBox="0 0 70 73"
  >
    <g fill="none" fillRule="nonzero">
      <path fill="#EFEFEF" d="M70.05 72.05h-70V-.05z" />
      <path fill="#12B2B2" d="M61.1 7v35H25z" />
      <path fill="#EFEFEF" d="M43 24.1H24V5z" />
    </g>
  </svg>
)

const TriangleDreams = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="73"
    height="70"
    viewBox="0 0 73 70"
  >
    <g fill="none" fillRule="nonzero">
      <path fill="#EFEFEF" d="M72.1 0v70H0z" />
      <path fill="#FFBF68" d="M13 57V28h30.1z" />
      <path fill="#EFEFEF" d="M24 9h19v19.1z" />
    </g>
  </svg>
)

const TriangleExcellence = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="71"
    height="73"
    viewBox="0 0 71 73"
  >
    <g fill="none" fillRule="nonzero">
      <path fill="#EFEFEF" d="M.05.95h70v72.1z" />
      <path fill="#7654CC" d="M42.1 31v32H9z" />
      <path fill="#EFEFEF" d="M25.1 31v16H9z" />
    </g>
  </svg>
)

export { TriangleLife, TriangleDreams, TriangleExcellence }
