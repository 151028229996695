import React, { Component } from 'react'
import style from './style.module.scss'

import { TrianglesPortfolio } from './Icons'
import PortfolioDesktop from './PortfolioDesktop'
import PortfolioTablet from './PortfolioTablet'
import PortfolioMobile from './PortfolioMobile'

class Portfolio extends Component {
  state = {}

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <TrianglesPortfolio className={style.trianglesPortfolio} />
        <div className={style.portfolioContainer}>
          <h3>Portfólio</h3>
          <PortfolioDesktop />
          <PortfolioTablet />
          <PortfolioMobile />
        </div>
      </div>
    )
  }
}

export default Portfolio
