import React from 'react'

const Pencil = ({ colorFill, opacityActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 29 29"
  >
    <g style={{ fill: colorFill, opacity: opacityActive }} fillRule="evenodd">
      <path d="M16.337 5.85L0 22.187V29h6.812l16.337-16.337zM28.23 4.83L24.178.767a1.938 1.938 0 0 0-1.38-.571c-.52 0-1.009.203-1.375.57l-3.061 3.06 6.813 6.813 3.055-3.055c.759-.759.76-1.994.002-2.752" />
    </g>
  </svg>
)

const Clipboard = ({ colorFill, opacityActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="31"
    viewBox="0 0 23 31"
  >
    <g style={{ fill: colorFill, opacity: opacityActive }} fillRule="evenodd">
      <path d="M19.686 4.196h-.896v4.301c0 .872-.71 1.58-1.58 1.58H5.495a1.582 1.582 0 0 1-1.58-1.58V4.196h-.898A3.023 3.023 0 0 0 0 7.214v19.954a3.024 3.024 0 0 0 3.02 3.02h16.667a3.023 3.023 0 0 0 3.019-3.02V7.214a3.022 3.022 0 0 0-3.019-3.018" />
      <path d="M14.831 2.706a.136.136 0 0 1-.135-.134c-.07-1.348-1.977-2.42-3.344-2.42-1.365 0-3.19 1.072-3.259 2.42a.138.138 0 0 1-.136.134h-1.63a.575.575 0 0 0-.574.575V7.45c0 .318.257.575.574.575h10.05a.575.575 0 0 0 .576-.575V3.28a.575.575 0 0 0-.575-.575H14.83z" />
    </g>
  </svg>
)

const Shovel = ({ colorFill, opacityActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="29"
    viewBox="0 0 34 29"
  >
    <defs>
      <path id="a" d="M0 0h34v28.212H0z" />
    </defs>
    <g
      style={{ fill: colorFill, opacity: opacityActive }}
      fill="none"
      fillRule="evenodd"
    >
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        d="M22.762 12.983c.146-.026.288-.081.416-.165l9.141-6.006a3.734 3.734 0 0 0 1.072-5.161C32.87.859 32.01.286 31.026.082c-.98-.206-2.001-.023-2.798.5l-9.144 6.005c-.243.16-.412.407-.471.693-.059.28 0 .577.158.82l1.074 1.632-2.295 3.829-.924-1.409c-1.323-2.017-3.251-3.149-5.286-3.149-.31 0-.62.028-.933.08-1.756.299-3.33 1.435-4.425 3.199L1.146 20.05C-.788 23.163.2 25.323.714 26.107c.516.78 2.106 2.542 5.73 2.006h-.002l9.232-1.383c2.33-.395 4.132-1.698 4.946-3.568.821-1.885.536-4.116-.782-6.119l-.955-1.455 2.284-3.824.449.668c.29.437.72.625 1.146.55"
        mask="url(#b)"
      />
    </g>
  </svg>
)

const Triangles = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="260"
    height="261"
    viewBox="0 0 260 261"
  >
    <g fill="none" fillRule="nonzero" opacity=".5">
      <path fill="#EFEFEF" d="M104 157H0l52-52zM260 261H156l52-52z" />
      <path fill="#B9BFC8" d="M0 52h104l-52 52zM156 156h104l-52 52z" />
      <path
        fill="#EFEFEF"
        d="M208 52v104L104 52zM104 156h52l-52 52zM208 52V0l52 52z"
      />
    </g>
  </svg>
)

export { Pencil, Clipboard, Shovel, Triangles }
