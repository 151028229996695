import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/Header'
import Services from '../components/Services'
import Portfolio from '../components/Portfolio'
import Team from '../components/Team'
import InstagramFeed from '../components/InstagramFeed'
import ContactBoxes from '../components/ContactBoxes'
import Footer from '../components/Footer'

import '../styles/global.scss'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image imageFileName="banner1.jpeg" />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
    <Header />
    <div>
      <Services />
      <Portfolio />
      <Team />
      <InstagramFeed
        title="Fique por dentro dos projetos mais recentes"
        mobileTitle="Nosso Instagram"
      />
      <ContactBoxes />
      <Footer />
    </div>
  </Layout>
)

export default IndexPage
