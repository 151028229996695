import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import multiClass from '../../helpers/multiClass'

class ServicesMobile extends Component {
  state = {
    currentBox: ''
  }

  active = item => {
    const { number } = item
    const { currentBox } = this.state
    this.setState({ currentBox: currentBox === number ? '' : number })
  }

  showDescrioption = (currentBox, item) => {
    return (
      currentBox === item.number && (
        <div
          style={{ '--selected-box-color': item.color }}
          className={multiClass(style.descriptionContainer)}
        >
          {item.description}
        </div>
      )
    )
  }

  render() {
    const { currentBox } = this.state
    const { servicesLIst } = this.props
    return (
      <div className={style.servicesMobileContainer}>
        <div className={style.boxContainer}>
          {servicesLIst.map(item => (
            <Fragment key={item.number}>
              <div
                onClick={() => {
                  this.active(item)
                }}
                className={style.serviceBox}
                role="button"
                onKeyDown={() => {}}
                tabIndex="-1"
                style={{ '--selected-box-color': item.color }}
              >
                <div style={{ marginRight: item.iconMarginMobile }}>
                  {currentBox === item.number ? item.iconActive : item.icon}
                </div>
                <div className={style.serviceText}>
                  <h3>{item.title}</h3>
                  <p>{item.subTitle}</p>
                  {currentBox === item.number ? (
                    <p className={style.lessMore}>ver menos</p>
                  ) : (
                    <p className={style.lessMore}>ver mais</p>
                  )}
                </div>
              </div>
              {this.showDescrioption(currentBox, item)}
            </Fragment>
          ))}
        </div>
      </div>
    )
  }
}

ServicesMobile.propTypes = {
  servicesLIst: PropTypes.instanceOf(Array)
}

ServicesMobile.defaultProps = {
  servicesLIst: []
}

export default ServicesMobile
