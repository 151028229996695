import React, { Component } from 'react'
import Slider from 'react-slick'

import multiClass from '../../helpers/multiClass'
import { RightArrow, LeftArrow } from './Arrows'
import { PlayArrow } from '../PlayIcon'
import { TriangleLife, TriangleDreams, TriangleExcellence } from './Triangles'

import headerRightBanner from '../../images/header-right-banner.jpeg'
import courseBanner from '../../images/course-banner.png'
import headerLife from '../../images/header-life.jpg'

import style from './style.module.scss'
import WhatsAppButton from '../WhatsappButton'

class HeaderSlider extends Component {
  slider = React.createRef()

  componentDidMount() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 150)
  }

  next = () => {
    this.slider.current.slickNext()
  }

  previous = () => {
    this.slider.current.slickPrev()
  }

  goToSlide = index => {
    if (this.slider) {
      return this.slider.current.innerSlider.slickGoTo(index)
    }

    return true
  }

  currentSlide = () => {
    return this.slider.current.innerSlider.state.currentSlide
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      centerPadding: '50px'
    }

    const bannerList = [
      {
        number: '01',
        title: 'qualidade de vida',
        videoLink: 'https://youtube.com',
        image: headerLife,
        titleColorClass: 'colorTopaz',
        triangle: <TriangleLife />,
        link: 'https://www.youtube.com/watch?v=-gd4F91x5hU&t=83s',
        linkText: 'Conheça o StudioM4',
        linkBackgroundColor: '#12b2b2'
      },
      {
        number: '02',
        title: 'materialização',
        secondTitle: 'de sonhos',
        videoLink: 'https://youtube.com',
        image: headerRightBanner,
        titleColorClass: 'colorApricot',
        triangle: <TriangleDreams />,
        link:
          'https://www.youtube.com/watch?v=CgmH3EMh440&list=PLD8zVlpANEbV39Jb8f9_u0QOdJcdppw9-',
        linkText: 'Saiba mais no nosso canal',
        linkBackgroundColor: '#ffbf68'
      }
      // ,
      // {
      //   number: '03',
      //   title: 'detalhamento de',
      //   secondTitle: 'excelência',
      //   videoLink: 'https://youtube.com',
      //   image: courseBanner,
      //   titleColorClass: 'colorIndigo',
      //   triangle: <TriangleExcellence />,
      //   link: '/arquitetura-na-pratica',
      //   linkText: 'Curso Arquitetura na Prática',
      //   linkBackgroundColor: '#7654cc'
      // }
    ]

    return (
      <div className={style.headerSlider}>
        <Slider {...settings} ref={this.slider}>
          {bannerList.map(item => (
            <div key={item.number}>
              <div className={style.headerImageContainer}>
                <div
                  className={style.headerImage}
                  style={{
                    backgroundImage: `url(${item.image})`
                  }}
                  >
                  <WhatsAppButton/> 
                </div>
                <div
                  className={multiClass(
                    style.back,
                    style[item.titleColorClass]
                  )}
                  onClick={this.previous}
                  role="button"
                  onKeyDown={() => {}}
                  tabIndex="-1"
                >
                  <LeftArrow className={style[item.titleColorClass]} />
                </div>
                <div
                  className={multiClass(
                    style.next,
                    style[item.titleColorClass]
                  )}
                  onClick={this.next}
                  role="button"
                  onKeyDown={() => {}}
                  tabIndex="-1"
                >
                  <RightArrow className={style[item.titleColorClass]} />
                </div>
                <a
                  className={style.sliderLinkRectangle}
                  style={{ backgroundColor: item.linkBackgroundColor }}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <div className={style.linkContainer}>
                    <PlayArrow />
                    <div className={style.circlePulseContainer}>
                      <p>{item.linkText}</p>
                      <div className={style.circlePulse} />
                      <div
                        className={style.circleFixed}
                        style={{ background: item.linkBackgroundColor }}
                      />
                    </div>
                  </div>
                </a>
              </div>
              <div className={style.triangleContainer}>{item.triangle}</div>
              <div className={style.headerTitleContainer}>
                <h2 className={style.fixedTitle}>
                  <span>Arquitetura é</span>
                </h2>
                <h2 className={style[item.titleColorClass]}>
                  <span>{item.title}</span>
                </h2>
                {item.secondTitle && (
                  <h2 className={style[item.titleColorClass]}>
                    <span>{item.secondTitle}</span>
                  </h2>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

export default HeaderSlider
