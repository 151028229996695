import { Link } from 'gatsby'
import React from 'react'

import multiClass from '../../helpers/multiClass'
import style from './style.module.scss'
import logo from '../../images/logo-m4.svg'
import { InstagramIcon, YoutubeIcon } from '../SocialIcons'

import MobileMenu from '../MobileMenu'
import HeaderSlider from '../HeaderSlider'

const Header = () => (
  <header className={style.header}>
    <div className={style.menuContainer}>
      <Link to="/" className={style.logom4}>
        <img src={logo} alt="Logo Studio M4" />
      </Link>
      <a
        className={multiClass(style.socialLink, style.instagramLink)}
        href="https://www.instagram.com/studiom4"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon />
      </a>
      <a
        href="https://www.youtube.com/channel/UCeyH-g2_EXMFpGlTM9v6ZJg"
        className={multiClass(style.socialLink, style.youtubeLink)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <YoutubeIcon />
      </a>
      <Link to="/#contato" className={style.headerLink}>
        CONTATO
      </Link>
      {/* <Link to="/arquitetura-na-pratica" className={style.headerLink}>
        ARQUITETURA NA PRÁTICA
      </Link> */}
      <MobileMenu className={style.mobileMenu} contatctLink="/#contato" />
    </div>
    <div className={style.sliderContainer}>
      <HeaderSlider />
    </div>
  </header>
)

export default Header
