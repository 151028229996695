import React from 'react'

const TrianglesPortfolio = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="205"
    height="210"
    viewBox="0 0 205 210"
    className={className}
  >
    <g fill="none" fillRule="nonzero" opacity=".5">
      <path fill="#EFEFEF" d="M102 0v104L50 52zM205 210H101l52-52z" />
      <path fill="#B9BFC8" d="M-4 104V0l52 52zM101 105h104l-52 52z" />
      <path fill="#EFEFEF" d="M-3 104h104L-3 208z" />
    </g>
  </svg>
)

export { TrianglesPortfolio }
