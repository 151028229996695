import React, { Fragment } from 'react'
import { PlayArrow } from '../PlayIcon'

const ProjectsDescription = ({
  className,
  circlePulseContainer,
  circlePulse
}) => (
  <Fragment>
    <p>
      Aqui no <b>StudioM4</b> a gente acredita que arquitetura é provedora de
      qualidade de vida. Não é só de solução estética que se concebe um projeto,
      desenvolvemos todos os nossos projetos com uma qualidade técnica de
      excelência, temos muito orgulho disso. Três etapas compõem este serviço:{' '}
      <b>estudo preliminar</b>, <b>anteprojeto</b> e <b>projeto executivo</b>,
      vamos da maquete 3D até o detalhamento de cada solução pro seu espaço.
    </p>
    <a
      href="https://www.youtube.com/watch?v=X8-kA9hGqTo"
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      <PlayArrow />
      <div className={circlePulseContainer}>
        <h4>Veja um de nossos projetos</h4>
        <div className={circlePulse} />
      </div>
    </a>
  </Fragment>
)

const ConsultDescription = ({
  className,
  circlePulseContainer,
  circlePulse
}) => (
  <Fragment>
    <p>
      Com valor mais acessível que o projeto e prazo de atendimento bem mais
      rápido.
    </p>
    <a
      href="https://api.whatsapp.com/send?phone=556195697995"
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      <PlayArrow />
      <div className={circlePulseContainer}>
        <h4>Fale conosco para saber mais</h4>
        <div className={circlePulse} />
      </div>
    </a>
  </Fragment>
)

const ConstructionDescription = ({
  className,
  circlePulseContainer,
  circlePulse 
}) => (
  <Fragment>
    <p>
      Este serviço é <b>exclusivo para nossos clientes de projeto</b>, em
      Brasília. Administramos todo o andamento da sua obra, desde o planejamento
      físico-financeiro até a execução de cada detalhe, pra que seu sonho vire
      realidade.
    </p>
    <a
      href="https://www.youtube.com/watch?v=C-5L7y-Mrag"
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      <PlayArrow />
      <div className={circlePulseContainer}>
        <h4>Entenda como executamos</h4>
        <div className={circlePulse} />
      </div>
    </a>
  </Fragment>
)

export { ProjectsDescription, ConsultDescription, ConstructionDescription }
