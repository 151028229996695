import React, { Component } from 'react'
import style from './style.module.scss'
import { Pencil, Clipboard, Shovel, Triangles } from './ServicesIcons'
import {
  ProjectsDescription,
  ConsultDescription,
  ConstructionDescription
} from './Descriptions'
import ServicesMobile from './ServicesMobile'
import multiClass from '../../helpers/multiClass'

class Services extends Component {
  state = {
    currentBox: '01'
  }

  active = item => {
    this.setState({ currentBox: item.number })
  }

  render() {
    const { currentBox } = this.state
    const servicesList = [
      {
        number: '01',
        title: 'Projetos',
        subTitle: 'Um bom projeto transforma espaços e materializa sonhos',
        icon: <Pencil />,
        iconActive: <Pencil colorFill="#12b2b2" opacityActive="1" />,
        iconMarginMobile: '20px',
        description: (
          <ProjectsDescription
            className={style.linkContainer}
            circlePulseContainer={style.circlePulseContainer}
            circlePulse={style.circlePulse}
          />
        ),
        color: '#12b2b2'
      },
      {
        number: '02',
        title: 'Consultoria',
        subTitle: 'A consultoria é o serviço express do StudioM4',
        icon: <Clipboard />,
        iconActive: <Clipboard colorFill="#f59846" opacityActive="1" />,
        iconMarginMobile: '25px',
        description: (
          <ConsultDescription
            className={style.linkContainer}
            circlePulseContainer={style.circlePulseContainer}
            circlePulse={style.circlePulse}
          />
        ),
        color: '#f59846'
      },
      {
        number: '03',
        title: 'Obras',
        subTitle: 'Administramos todo o andamento da sua obra',
        icon: <Shovel />,
        iconActive: <Shovel colorFill="#7654cc" opacityActive="1" />,
        iconMarginMobile: '15px',
        description: (
          <ConstructionDescription
            className={style.linkContainer}
            circlePulseContainer={style.circlePulseContainer}
            circlePulse={style.circlePulse}
          />
        ),
        color: '#7654cc'
      }
    ]
    return (
      <div className={style.servicesContainer}>
        <div
          className={multiClass(style.boxContainer, style.boxContainerDesktop)}
        >
          {servicesList.map(item => (
            <div
              key={item.number}
              onClick={() => {
                this.active(item)
              }}
              className={style.serviceBox}
              role="button"
              onKeyDown={() => {}}
              tabIndex="-1"
              style={{ '--selected-box-color': item.color }}
            >
              {currentBox === item.number ? item.iconActive : item.icon}
              <h3>{item.title}</h3>
              <p>{item.subTitle}</p>
              <div className={style.hoverBorderBox} />
              {currentBox === item.number && (
                <div className={style.selectedBox} />
              )}
            </div>
          ))}
        </div>
        {servicesList.map(item => (
          <div
            style={{ '--selected-box-color': item.color }}
            key={item.number}
            className={multiClass(
              style.descriptionContainer,
              style.descriptionDesktop
            )}
          >
            {currentBox === item.number && item.description}
          </div>
        ))}
        <ServicesMobile servicesLIst={servicesList} />
        <div className={style.trianglesBackground}>
          <Triangles />
        </div>
      </div>
    )
  }
}

export default Services
