import React, { Component } from 'react'
import style from './style.module.scss'
import multiClass from '../../helpers/multiClass'
import {
  InstagramIcon,
  LinkedinIcon,
  PortfolioIcon,
  PinterestIcon
} from '../SocialIcons'
import SocialLinksMobile from './SocialLinksMobile'

import {
  ImiraIcon,
  TrianglesTeam,
  AnnaIcon,
  NicolasIcon,
  JuliaIcon,
  MylenaIcon,
  TherezaIcon,
  AliceIcon
} from './TeamIcons'

class Team extends Component {
  state = {}

  render() {
    const teamList = [
      {
        number: '01',
        name: 'Imira de Holanda',
        role: 'Arquiteta Sênior, CEO & Founder',
        icon: <ImiraIcon />,
        instagram: 'https://www.instagram.com/studiom4',
        linkedin: 'https://www.linkedin.com/in/imira-de-holanda-matos-a56816ba',
        pinterest: 'https://br.pinterest.com/studiom44334'
      },
      {
        number: '02',
        name: 'Anna Szczepanski',
        role: 'Arquiteta Sênior, Gerente de Projetos',
        icon: <AnnaIcon />,
        instagram: 'https://www.instagram.com/annabento.arq',
        linkedin: 'https://bit.ly/linkedin_annaszbento',
        pinterest: 'https://br.pinterest.com/annaszbento'
      },
      {
        number: '03',
        name: 'Thereza de Holanda',
        role: 'Secretária',
        icon: <TherezaIcon />,
        instagram: 'https://www.instagram.com/teca.holanda'
      },
      {
        number: '04',
        name: 'Mylena Oliveira',
        role: 'Arquiteta Plena',
        icon: <MylenaIcon />,
        instagram: 'https://www.instagram.com/mylenaoliveira.arq',
        linkedin: 'https://www.linkedin.com/in/mylena-oliveira-04491992/',
        portfolio: 'https://mylenalo.wixsite.com/portfolio'
      },
      {
        number: '05',
        name: 'Nicolas Fleischmann',
        role: 'Arquiteto Júnior',
        icon: <NicolasIcon />,
        instagram: 'https://www.instagram.com/nicolasfp',
        linkedin: 'https://www.linkedin.com/in/nicolas-fleischmann-543747a2'
      },
      {
        number: '06',
        name: 'Julia Andrade',
        role: 'Arquiteta Estagiária',
        icon: <JuliaIcon />,
        instagram: 'https://www.instagram.com/juliamessias_',
        linkedin: 'https://www.linkedin.com/in/julia-messias-0374a6175/'
      },
      {
        number: '07',
        name: 'Alice de Holanda',
        role: 'Diretora e editora de vídeo e fotografia',
        icon: <AliceIcon />,
        instagram: 'https://www.instagram.com/atelievoador'
      }
    ]
    return (
      <div className={style.teamContainer}>
        <TrianglesTeam className={style.trianglesTeam} />
        <div className={style.tableContainer}>
          <h3>Siga nosso time</h3>
          <table>
            <tbody>
              <tr>
                <th />
                <th />
                <th className={style.memberRoleColumn} />
                <th>
                  <InstagramIcon />
                </th>
                <th>
                  <LinkedinIcon />
                </th>
                <th>
                  <PinterestIcon />
                </th>
                <th>
                  <PortfolioIcon />
                </th>
              </tr>
              {teamList.map(member => (
                <tr key={member.number}>
                  <td className={style.memberIcon}>{member.icon}</td>
                  <td className={style.memberName}>
                    {member.name}
                    <span className={style.memberRole}>{member.role}</span>
                    <SocialLinksMobile member={member} />
                  </td>
                  <td className={style.memberRole}>{member.role}</td>
                  <td
                    className={multiClass(style.links, style.memberInstagram)}
                  >
                    <a
                      href={member.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div />
                    </a>
                  </td>
                  <td className={multiClass(style.links, style.memberLinkedin)}>
                    <a
                      href={member.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div />
                    </a>
                  </td>
                  <td
                    className={multiClass(style.links, style.memberPinterest)}
                  >
                    <a
                      href={member.pinterest}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div />
                    </a>
                  </td>
                  <td
                    className={multiClass(style.links, style.memberPortfolio)}
                  >
                    <a
                      href={member.portfolio}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default Team
